<template>
  <div class="login">
    <loading :active="isLoading" :is-full-page="false" :opacity="0" :color="'#007bff'" />

    <div class="password">
      <input type="password" placeholder="Password" class="password__field" autofocus @keypress.enter="login"
             v-model="password" @keydown="error = ''"><i class="password__key fas fa-key"></i>
    </div>

    <div class="login__control">
      <button @click="register" style="background-color: rgba(250, 200, 50, 0.4)"><i class="fas fa-plus-square"></i> New workspace</button>
      <button @click="login" style="background-color: rgba(0, 255, 150, 0.4)"><i class="fas fa-sign-in-alt"></i> Log In</button>
    </div>
    <div class="login__warning" v-show="error">
      {{ error }}
    </div>
  </div>
</template>

<script>

import Loading from 'vue-loading-overlay'

export default {
  name: 'Login',
  components: {
    Loading
  },
  data () {
    return {
      password: '',
      error: ''
    }
  },
  computed: {
    isLoading: {
      get() {
        return this.$store.getters['Auth/isLoading'] || this.$store.getters['Account/isLoading']
      },
      set() {}
    }
  },
  methods: {
    login () {
      this.$store.dispatch('Auth/signIn', this.password)
        .then(() => {
          this.$router.push({ name: 'Index' })
        })
        .catch(error => {
          this.error = error.message
        })
    },
    register () {
      this.$store.dispatch('Account/registration', this.password)
          .then(() => {
            this.$router.push({ name: 'Index' })
          })
          .catch(error => {
            this.error = error.message
          })
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 350px;
  padding: 20px;
  margin-top: 33vh;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  color: #222;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(25px);

  &__label {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 15px;
    text-align: center;
  }

  &__warning {
    margin-top: 15px;
    font-size: 16px;
    color: #ffc900;
  }

  &__control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;


    button {
      font-size: 18px;
      background-color: rgba(255, 255, 255, 0.3);
      border: none;
      padding: 8px 16px;
      line-height: 1;
      border-radius: 5px;
      white-space: nowrap;

      &:hover {
        background-color: rgba(255, 255, 255, 0.5) !important;
      }
    }
  }
}

.password {
  position: relative;
  width: 100%;
  margin-bottom: 1rem;

  &__field {
    display: block;
    padding: 10px 45px;
    width: 100%;
    border: none;
    font-size: 24px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.3);

    &::placeholder {
      color: #333;
    }

    &:focus,
    &:active {
      outline: 0;
    }
  }

  & .password__key {
    position: absolute;
    top: 14px;
    left: 14px;
    color: #333;
    font-size: 20px;
  }
}
</style>
